import {
  MantineProvider,
  ColorSchemeProvider,
  // ColorScheme,
} from "@mantine/core";
import { useState } from "react";

import type { ColorScheme } from "@mantine/core";

import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { StylesPlaceholder } from "@mantine/remix";
import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';

import { emotionCache } from "./utils/emotionCache";

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'Lite ERP',
  viewport: 'width=device-width,initial-scale=1',
  description:
    'Somos una empresa familiar ubicada en la localidad de Hurlingham desde sus comienzos. ' +
    'Experiencia, confianza y una larga trayectoria de más de 60 años en el rubro, ' +
    'son algunos de los aspectos que nos representan.',
});

export const links: LinksFunction = () => [
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href:
      'https://fonts.googleapis.com/css2' +
      '?family=Hanken+Grotesk:wght@300;400;500' +
      '&family=Poppins:wght@300;400;500' +
      '&family=Source+Code+Pro:wght@300;400;500',
  },
];

export default function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
      emotionCache={emotionCache}
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider labels={{ confirm: 'Aceptar', cancel: 'Cancelar' }}>
          <NotificationsProvider>
            <html lang="en">
              <head>
                <StylesPlaceholder />
                <Meta />
                <Links />
              </head>
              <body>
                <Outlet />
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
              </body>
            </html>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
